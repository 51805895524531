import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Fade from "react-reveal/Fade"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ReinventingDevices = () => {
  return (
    <>
      <Layout>
        <Seo title="Reinventing devices for today and tomorrow" />
        <Fade top>
          <div className="py-10 lg:py-12 bg-dusk"></div>
        </Fade>
        <div className="py-24">
          <div className="xl:pr-96">
            <div className="container mx-auto text-dusk">
              <div>
                <div className="text-5xl font-firs-medium">
                  Reinventing devices for
                  <div>today and tomorrow:</div>
                </div>
                <div className="text-2xl mt-4">
                  Through the lens of wearable technology
                  <div>and smart textiles</div>
                </div>
                <div className="mt-4">
                  Sep 16, 2021 | Product Engineering, Audio Devices, Smart
                  Textiles
                </div>
              </div>
              <div className=" my-7  py-10">
                <div>
                  <StaticImage
                    alt="Softmatter"
                    src="../../images/news/reinventing/Smart-Homes.jpg"
                  />
                </div>
                <div className="my-4">
                  Smart soft technologies ushering a new age of advanced
                  personal devices – a focus in the audio device market.
                </div>
                <div className="mb-4">
                  “Hey Siri. Play some music that I like…”
                </div>
                <div className="mb-4">
                  We’re connected in ways – we could only imagine before.
                </div>
                <div className="mb-4">
                  Virtual monitoring technology. Voice-command speakers. A
                  network of in-home smart devices. The modern age is one of
                  intimate connections through digital mediums: now more than
                  ever, consumers have evolving expectations about what
                  technology can and should do to improve their lives. This is a
                  new time, distinguished by a new set of expectations.
                  Tomorrow’s consumer products are moving beyond 2D touch to 3D
                  immersive realities and adding smart features intuitively to
                  the end-user experience.
                </div>
                <div className="mb-4">
                  What factors are motivating this tech-forward shift? The
                  answer might be consumers themselves. Tech-users are
                  increasingly relying on self-conducted research to understand
                  new technology trends and market disruptions. Accordingly,
                  previously cutting-edge features such as voice-enabled devices
                  and 3D audiovisual entertainment are now core components of
                  the technologically adept customer experience. As new features
                  in our homes proliferate, from environmental controls and
                  sophisticated lighting to complete house automation; the
                  demand for feature-rich and intuitive interfaces are on the
                  increase.
                </div>
                <div className="mb-4">
                  These trends [1] are only expected to grow in the aftermath of
                  the COVID-19 pandemic. As a result of stay-at-home mandates,
                  the post-pandemic world will be more smart-technology
                  inclined, not less — thus, device manufacturers must work to
                  develop new smart experiences and channel their efforts that
                  will continue to surprise and delight consumers.
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Audio Devices: Trajectory of a Significant Market
                  </div>
                  <div className="mb-4">
                    In addition to other ease-of-use home devices, audio devices
                    remain one of the fastest-growing markets [2] in the
                    smart-tech industry. Consumer interest in smart speakers,
                    soundbars, home theater equipment and systems with built-in
                    audio capabilities is a direct response to a greater
                    customer understanding of the capabilities these devices
                    have to improve and reimagine their most important
                    investment — their home.
                  </div>
                  <div className="mb-4">
                    Younger people, meanwhile, are increasingly using wireless
                    headphones and earbuds to separate themselves from their
                    environments and those around them – on a subway, in an
                    office, and even at home – creating their own intimate,
                    absorbed environment. As our cities become bigger, people
                    have longer commutes, and the quality and amount of
                    diversion increase, the need for ‘isolation devices’ will
                    only continue grow.
                  </div>
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/news/reinventing/Audio-Opportunities.jpg"
                    />
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Audio Opportunities Meet Smart Textiles and Wearable
                    Technology
                  </div>
                  <div className="mb-4">
                    Technology producers might wonder: what place does smart
                    textile technology have in the consumer audio market? As it
                    stands, smart soft features can be readily integrated with
                    existing user interfaces to develop advancements for
                    products consumers are already familiar with — effectively
                    revitalizing a market poised to benefit from immediate
                    innovation.
                  </div>
                  <div className="mb-4">
                    Innovations may come in the form of sensors that monitor and
                    respond to human-machine interactions (HMI). Alternatively,
                    they do allow for the creation of controls such as buttons
                    and switches with built-in smart features directly embedded
                    within the device covering or fabric.
                  </div>
                  <div className="mb-4">
                    Adding to these solutions is wearable technology. Sleep
                    based wearables such as earbuds, that collect and transfer
                    biometric data between wearers and in-home devices to create
                    a vast network of opportunities that revolutionizes the way
                    customers sync with multi-device integration.
                  </div>
                  <div className="mb-4">
                    But beyond all this, smart textile technologies and wearable
                    technologies make for a great user experience and allows for
                    robust design build as they are light, thin, and easy to
                    assemble – especially beneficial in the consumer electronics
                    space.
                  </div>
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/news/reinventing/Smart-Soft-Audio-Solutions.jpg"
                    />
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Smart Soft Solutions for Smarter Interaction
                  </div>
                  <div className="mb-4">
                    At Softmatter, we work towards collaborating with consumer
                    electronic brands with the goal of advancing the smart
                    electronics market — and the overall customer experience.
                    This has enabled us in developing world-class platforms for
                    the evolving movement of wearable technology: the textile
                    itself.
                  </div>
                  <div className="mb-4">
                    Our smart textile technologies combined with user-centric
                    interfaces can produce a variety of exciting new products.
                    The possibilities are countless -whether it’s a
                    textile-based sensor that can realize and respond to
                    interactions or a conductive textile that enables data
                    transmission between a human and a devise. As the
                    technologies are contained within the thin light layer of
                    the fabric – our platforms allow for all new architecture
                    designs and heterogeneous integrations.
                  </div>
                  <div className="mb-4">
                    When it comes to audio devices and other smart technology,
                    consumers have spoken: many want intuitively designed
                    products that offer a broader range of accessible
                    capabilities than current marketplace solutions provide. Our
                    response to such needs come in the form of:
                  </div>
                  <div className="mb-4 pl-8">
                    + Creation of intelligent devices that respond to
                    user-designed patterns with interactive fabric surfaces for
                    buttons, switches and sticks.
                  </div>
                  <div className="mb-4 pl-8">
                    + 3D Engineered Knit premium textile-based exterior cover to
                    seamless tubes can be created via Flat, Circular, Warp
                    Knits, and Narrow Width Fabrics.
                  </div>
                  <div className="mb-4 pl-8">+ Creation</div>
                  <div className="mb-4 pl-8">
                    + Potential for new ways of interacting with devices –
                    Gesture control. Technology that allows an intuitive method
                    of interaction, that removes the need for controls and
                    buttons altogether.
                  </div>
                  <div className="mb-4 pl-8">
                    + Injection molding for better grip, encasings and impact
                    protection.
                  </div>
                  <div className="mb-4 pl-8">
                    + Lighting effects that can be integrated and paired with
                    audio devices through intuitive RGB lighting active
                    technology
                  </div>
                  <div className="mb-4 pl-8">
                    + Advanced surface modification for product durability with
                    immunity to electromagnetic noise, water, heat, electrical
                    surges and other environmentally damaging conditions.
                  </div>
                  <div className="mb-4 pl-8">
                    + Programmatic linking and actuating software that responds
                    to external stimuli.
                  </div>
                  <div className="mb-4 pl-8">
                    + Aesthetic product designs that complement existing in-home
                    consumer style choices and provide superior soft-touch feel.
                  </div>
                  <div className="mb-4 pl-8">
                    + Audio wearables with built-in controls incorporated for
                    exceptional equipment regulation — with capacitive and
                    resistive touch technologies.
                  </div>
                  <div className="mb-4 pl-8">
                    + Conductive yarn woven into the devise fabric to produce
                    functionality for controlling devices remotely.
                  </div>
                  <div className="mb-4 pl-8">
                    + Technologies that allow device manufacturers to simplify
                    user interfaces in a cost-effective manner by integrating
                    and using sensors in three dimensions. Varied sensors can be
                    used in combination to create a full stack solution that can
                    be placed above, below, or around a mixture of flexible and
                    rigid control displays. Our sensors may also be integrated
                    with other types of surface materials such as foam.
                  </div>
                  <div className="mb-4">
                    These are just some — but not all — of the advancing smart
                    textile technologies offered by Softmatter. Uniquely
                    prepared to respond to demands in the market, we bring the
                    functionality of electronics together with the comfort and
                    adaptability of textiles.
                  </div>
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/news/reinventing/Injection-molding-for-better-encasings.jpg"
                    />
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Appeal in wearable technology for audiological devices
                  </div>
                  <div className="mb-4">
                    In addition to their implication as an innovative,
                    market-disrupting potential, the consumer electronic market
                    and smart textile technology are seamlessly designed to
                    appeal to consumers interested in wearables that can
                    continually track and transmit user data to networked
                    systems. Based on these expectations- Softmatter places
                    specific emphasis on product designs that champion
                    comfortable next-to-skin wearables. Products that enable
                    greater functionality, more convenience, and quick results.
                    All systems are made to be light and thin, with built-in
                    technology that is unobtrusive to the wearer even while in
                    use.
                  </div>
                  <div className="mb-4">
                    Product configurations for audiological wearables:
                  </div>
                  <div className="mb-4">
                    Applications may include textile-based mathematical
                    structures such as Engineered Knit for comfort and freedom
                    of movement​ combined with textile-based sensor electrodes
                    and conductive pathways.
                  </div>
                  <div className="mb-4">
                    Engineered smart soft solutions by Softmatter
                  </div>
                  <div className="mb-4">
                    By partnering with an experienced product development team,
                    high-tech consumer and industrial technology companies can:
                  </div>
                  <div className="mb-4 pl-8">
                    + Design and build from concept to production.
                  </div>
                  <div className="mb-4 pl-8">
                    + Solve unprecedented regulatory challenges while
                    accelerating time to market.
                  </div>
                  <div className="mb-4 pl-8">
                    + Quality commitment of ISO 9001 and 13485 facilities with
                    FDA Class I and II products in execution.
                  </div>
                  <div className="mb-4">
                    Speak to our experienced team of product engineers to
                    discuss how to incorporate the ever-advancing technology to
                    consumer electronic devices.
                  </div>
                  <div className="mb-4 mt-20 font-firs-medium">References</div>
                  <div className="mb-4">
                    <a
                      href="https://techcrunch.com/sponsor/xiaomi/new-xiaomi-survey-explores-how-covid-19-is-driving-the-new-smart-home-and-what-it-means-for-2021-and-beyond/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAEmqNmquBSzmIdtkJJF4SCgaqauKANNuJjZPBdwf914o3en5pM8vSzrVLkaOr0fP4aCn1TJRM10lUxBf7d1VyjIl7dKcenR-_GmlkKAMp_Z7djdS8O2vB-g9G6JY8QyqL7K5dGQNiF1CLK26vqK9CBTKoe0hAz-llct0g0bff1wp&renderMode=ie11"
                      target="_blank"
                      className="text-mint hover:underline"
                    >
                      New Xiaomi survey explores how Covid-19 is driving the new
                      smart home, and what it means for 2021 and beyond |
                      TechCrunch
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://www.canalys.com/newsroom/canalys-tws-smart-audio-shipments-Q1-2020"
                      target="_blank"
                      className="text-mint hover:underline"
                    >
                      Canalys Newsroom- Global smart audio device shipments Q1
                      2020
                    </a>
                  </div>
                  <div className="mt-20">
                    <div className="grid sm:grid-cols-2">
                      <div className="">
                        <StaticImage
                          alt="Softmatter"
                          src="../../images/news/Post2.png"
                        />
                        <div className="mb-2 text-sm mt-4 text-salmon font-firs-medium">
                          Game Changer for AR and VR: Smart Textiles
                        </div>
                        <div className="text-sm mb-2">
                          Sep 16, 2021 | Product Engineering, AR/VR, Smart
                          Textiles
                        </div>
                        <div className="text-sm mb-2">
                          The first stereoscope. Arcade machines. And a Sayre
                          Glove. Snapchat lenses, Microsoft HoloLens and Google
                          maps AR navigation systems that superimposing...
                        </div>
                        <div className="text-sm mt-2 text-salmon font-firs-medium">
                          <Link to="/news/game-changer-for-AR-and-VR">
                            <span className="hover:underline cursor-pointer">
                              Read More
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ReinventingDevices
